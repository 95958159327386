import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Blog = ({ data: { blog } }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <div className="site-container blogs-page" id="Blogs">
        <div className="container">
          <div className="section-head">
            <h1 className="line-heading h2">Blog</h1>
          </div>
          <ul
            className={`blogs-list ${blog.edges.length < 5 ? "few-blogs" : ""}`}
          >
            {blog.edges.map(({ node }, i) => {
              const { slug, title, featureImage, publishDate } = node;
              return (
                <li key={i} className="item">
                  <div className="inner">
                    <Link className="link" to={`/${slug}`} />
                    {featureImage ? (
                      <Img
                        fluid={featureImage.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    ) : (
                      <div className="no-image"></div>
                    )}
                    <div className="details">
                      <h3 className="title">{title}</h3>
                      <span className="date">
                        <i className="fas fa-calendar-alt"></i>{" "}
                        {moment(publishDate).format("LL")}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogsQuery {
    blog: allContentfulBlogs(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          slug
          featureImage {
            fluid(maxWidth: 1500) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          publishDate
        }
      }
    }
  }
`;
